import React from 'react'
import { Link } from 'gatsby'

export default ({ label, link }) => {
  return (
    <Link to={link} className="more">
      {label}
      <svg viewBox="0 0 24 24">
        <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z" />
      </svg>
    </Link>
  )
}