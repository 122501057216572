import React from 'react'
import * as Component from './index'

export default ({ label, link }) => {
  if (!label || !link) {
    return null
  }

  return (
    <div className="section-footer">
      <Component.MoreLink link={link} label={label} />
    </div>
  )
}