import React from 'react'
import { Link } from 'gatsby'
import { RichText } from 'prismic-reactjs'
import Img from 'gatsby-image'
import * as Component from './index'

export default ({ item }) => (
  <div className="post post--archive">
    <Link to={`/${item.node._meta.uid}`} className="post--archive__link">
      <span className="sr-only">{RichText.asText(item.node.title)}</span>
    </Link>
    <div className="post--archive__inner">
      <div className="post--archive__inner__thumb card hover">
        <Img
          fixed={item.node.imageSharp.childImageSharp.fixed}
          objectFit="cover"
          objectPosition="50% 50%"
        />
      </div>
      <div className="post--archive__inner__copy">
        <Component.Date date={item.node._meta.lastPublicationDate} />
        <h3>{RichText.asText(item.node.title)}</h3>
        <span className="post--archive__inner__copy__category">React</span>
      </div>
    </div>
  </div>
)