import React from 'react'
import { Link } from 'gatsby'
import { RichText } from 'prismic-reactjs'

export default ({ item }) => (
  <div className="card card--document">
    <Link to={`/doc/${item.node._meta.uid}`} dateTime={item.node._meta.lastPublicationDate}>
      <span>
        {item.node.image && (
          <img src={item.node.imageSharp.publicURL} width={item.node.image.dimensions.width} height={item.node.image.dimensions.height} alt={item.node.image.alt} />
        )}
      </span>
      {RichText.asText(item.node.title)}
    </Link>
  </div>
)