import React from 'react'
import { RichText } from 'prismic-reactjs'
import Img from 'gatsby-image'

export default ({ item }) => (
  <div className="card card--theme">
    <a className="card--theme__demo-link" href={item.node.demo_url.url} target="_blank" rel="noopener noreferrer">
      <span className="sr-only">{`${RichText.asText(item.node.name)} | Live demo`}</span>
    </a>
    <a className="card--theme__buy-link" href={item.node.buy_url.url} target="_blank" rel="noopener noreferrer">
      <span className="card--theme__buy-link__span">Buy for</span> {item.node.discounted_price ? (<><span className="line-through">{`$${item.node.price}`}</span> {`$${item.node.discounted_price}`}</>) : `$${item.node.price}`}
    </a>
    <div className="card--theme__thumb">
      <Img
        fixed={item.node.imageSharp.childImageSharp.fixed}
        objectFit="cover"
        objectPosition="50% 50%"
      />
      <span className="card--theme__thumb__tag btn">
        <svg viewBox="0 0 24 24">
          <path d="M12 6c3.79 0 7.17 2.13 8.82 5.5C19.17 14.87 15.79 17 12 17s-7.17-2.13-8.82-5.5C4.83 8.13 8.21 6 12 6m0-2C7 4 2.73 7.11 1 11.5 2.73 15.89 7 19 12 19s9.27-3.11 11-7.5C21.27 7.11 17 4 12 4zm0 5c1.38 0 2.5 1.12 2.5 2.5S13.38 14 12 14s-2.5-1.12-2.5-2.5S10.62 9 12 9m0-2c-2.48 0-4.5 2.02-4.5 4.5S9.52 16 12 16s4.5-2.02 4.5-4.5S14.48 7 12 7z" />
        </svg>
        Live demo
      </span>
    </div>
    <h3 className="card--theme__name">{RichText.asText(item.node.name)}</h3>
    <p className="card--theme__title">{RichText.asText(item.node.title)}</p>
  </div>
)