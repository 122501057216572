import React from 'react'
import * as Component from './index'

export default ({ title, label, link, border }) => {
  return (
    <div className={border ? 'section-title section-title--border' : 'section-title'}>
      <div className="row">
        <div className="col-12 col-lg">
          <h3>{title}</h3>
        </div>
        {label && link && (
          <div className="col-12 col-lg-auto">
            <Component.MoreLink link={link} label={label} />
          </div>
        )}
      </div>
    </div>
  )
}